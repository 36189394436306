import { NonFunctionProperties } from '@supy/core';

import { BaseSupplierReturnItem, SupplierReturnItemRequest } from '../../entities';
import { SupplierReturnChannelItemPayload } from './supplier-return-channel-item.payload';
import { SupplierReturnItemTaxRatePayload } from './supplier-return-item-tax-rate.payload';

export class SupplierReturnItemPayload extends BaseSupplierReturnItem {
  constructor(args: NonFunctionProperties<SupplierReturnItemPayload>) {
    super(args);
  }

  serialize(): SupplierReturnItemRequest {
    return {
      // Excluding random generated id.
      id: this.id.includes('-') ? null : this.id,
      appliedTax: new SupplierReturnItemTaxRatePayload({
        ...this.taxRate,
        itemPrice: this.price,
        itemQuantity: this.quantity,
      }).serialize(),
      channelItem: new SupplierReturnChannelItemPayload(this.channelItem).serialize(),
      comment: this.comment,
      price: this.price,
      quantity: this.quantity,
    };
  }
}
