import { getApiDetailsDecorator } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { SupplierReturnChannelItem } from './supplier-return-channel-item.entity';
import { SupplierReturnItemResponse } from './supplier-return-item.model';
import { SupplierReturnItemTaxRate } from './supplier-return-item-tax-rate.entity';

const ApiProperty = getApiDetailsDecorator<SupplierReturnItemResponse>();

export abstract class BaseSupplierReturnItem {
  protected constructor(args: NonFunctionProperties<BaseSupplierReturnItem>) {
    this.channelItem = args.channelItem;
    this.comment = args.comment;
    this.id = args.id;
    this.price = args.price ?? 0;
    this.quantity = args.quantity ?? 0;
    this.taxRate = args.taxRate;
  }

  @ApiProperty() readonly channelItem: SupplierReturnChannelItem;
  @ApiProperty() readonly comment: string;
  @ApiProperty() readonly id: string;
  @ApiProperty() readonly price: number;
  @ApiProperty() readonly quantity: number;
  @ApiProperty({ key: 'appliedTax' }) readonly taxRate: SupplierReturnItemTaxRate;
}

export class SupplierReturnItem extends BaseSupplierReturnItem {
  private constructor(args: NonFunctionProperties<SupplierReturnItem>) {
    super(args);
  }

  static deserialize(data: SupplierReturnItemResponse): SupplierReturnItem {
    return new SupplierReturnItem({
      channelItem: data.channelItem && SupplierReturnChannelItem.deserialize(data.channelItem),
      comment: data.comment,
      id: data.id,
      price: data.price,
      quantity: data.quantity,
      taxRate: data.appliedTax && SupplierReturnItemTaxRate.deserialize(data.appliedTax),
    });
  }
}
