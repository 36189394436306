import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BadgeStatus } from '@supy/components';

import { GrnStatus, OrderStatus } from '../../../../core';

export const OrderStatusMapper: Record<OrderStatus, BadgeStatus> = {
  [OrderStatus.Submitted]: 'info',
  [OrderStatus.Pending]: 'warn',
  [OrderStatus.PartialReceived]: 'warn',
  [OrderStatus.Received]: 'success',
  [OrderStatus.Confirmed]: 'primary',
  [OrderStatus.Draft]: 'grey',
  [OrderStatus.Rejected]: 'light-error',
  [OrderStatus.Modified]: 'grey',
  [OrderStatus.Discarded]: 'error',
  [OrderStatus.Processed]: 'success',
  [OrderStatus.Shipped]: 'warn',
};

@Component({
  selector: 'supy-order-icon-status-badge',
  templateUrl: './order-icon-status-badge.component.html',
  styleUrls: ['./order-icon-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderIconStatusBadgeComponent {
  @Input() readonly status: OrderStatus | GrnStatus;
  @Input() readonly text?: string;

  get statusText() {
    return this.text ?? this.status;
  }

  readonly orderStatusMapper = OrderStatusMapper;
}
