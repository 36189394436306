import {
  SupplierReturnCreateRequest,
  SupplierReturnLockManyRequest,
  SupplierReturnPostManyRequest,
  SupplierReturnUpdateRequest,
} from '../../core';
import { SupplierReturnsFilters, SupplierReturnsRequestMetadata } from '../state';

export class SupplierReturnsGet {
  static readonly type = '[SupplierReturns] Get';

  constructor(readonly payload: { id: string; fromCache?: boolean }) {}
}

export class SupplierReturnsGetMany {
  static readonly type = '[SupplierReturns] Get Many';
}

export class SupplierReturnsExportList {
  static readonly type = '[SupplierReturns] ExportList';
}

export class SupplierReturnsCreate {
  static readonly type = '[SupplierReturns] Create';

  constructor(readonly payload: { body: SupplierReturnCreateRequest }) {}
}

export class SupplierReturnsUpdate {
  static readonly type = '[SupplierReturns] Update';

  constructor(readonly payload: { readonly id: string; readonly body: SupplierReturnUpdateRequest }) {}
}

export class SupplierReturnsPost {
  static readonly type = '[SupplierReturns] Post';

  constructor(readonly id: string) {}
}

export class SupplierReturnsDownloadPdf {
  static readonly type = '[SupplierReturns] DownloadPdf';

  constructor(readonly id: string) {}
}

export class SupplierReturnsPostMany {
  static readonly type = '[SupplierReturns] Post Many';

  constructor(readonly payload: SupplierReturnPostManyRequest) {}
}

export class SupplierReturnsLock {
  static readonly type = '[SupplierReturns] Lock';

  constructor(readonly id: string) {}
}

export class SupplierReturnsLockMany {
  static readonly type = '[SupplierReturns] Lock Many';

  constructor(readonly payload: SupplierReturnLockManyRequest) {}
}

export class SupplierReturnsUnlock {
  static readonly type = '[SupplierReturns] Unlock';

  constructor(readonly id: string) {}
}

export class SupplierReturnsResetPostManyResponse {
  static readonly type = '[SupplierReturns] ClearPostMany';
}

export class SupplierReturnsInitFilters {
  static readonly type = '[SupplierReturns] InitFilters';
}

export class SupplierReturnsSetFilters {
  static readonly type = '[SupplierReturns] SetFilters';

  constructor(readonly payload: SupplierReturnsFilters) {}
}

export class SupplierReturnsPatchFilters {
  static readonly type = '[SupplierReturns] PatchFilters';

  constructor(readonly payload: Partial<SupplierReturnsFilters>) {}
}

export class SupplierReturnsResetFilters {
  static readonly type = '[SupplierReturns] ResetFilters';

  constructor(readonly payload?: Pick<SupplierReturnsFilters, 'start' | 'end'>) {}
}

export class SupplierReturnsPatchRequestMetadata {
  static readonly type = '[SupplierReturns] PatchRequestMetadata';

  constructor(readonly payload: Partial<SupplierReturnsRequestMetadata>) {}
}

export class SupplierReturnsGenerateDocumentNumber {
  static readonly type = '[SupplierReturns] GenerateDocumentNumber';
}
