import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BadgeStatus } from '@supy/components';

import { OrderStatus } from '../../../../core';

@Component({
  selector: 'supy-order-status-badge',
  templateUrl: './order-status-badge.component.html',
  styleUrls: ['./order-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusBadgeComponent {
  @Input() readonly status: OrderStatus;
  @Input() readonly text?: string;

  get statusText() {
    return this.text ?? this.localizedStatus;
  }

  readonly orderStatusMapper: Record<OrderStatus, BadgeStatus> = {
    [OrderStatus.Submitted]: 'info',
    [OrderStatus.Pending]: 'warn',
    [OrderStatus.PartialReceived]: 'warn',
    [OrderStatus.Received]: 'success',
    [OrderStatus.Confirmed]: 'primary',
    [OrderStatus.Draft]: 'error',
    [OrderStatus.Rejected]: 'light-error',
    [OrderStatus.Modified]: 'grey',
    [OrderStatus.Discarded]: 'grey',
    [OrderStatus.Processed]: 'success',
    [OrderStatus.Shipped]: 'warn',
  };

  get localizedStatus() {
    switch (this.status) {
      case OrderStatus.Rejected:
        return $localize`:@@statusRejected:Rejected`;
      case OrderStatus.Confirmed:
        return $localize`:@@statusConfirmed:Confirmed`;
      case OrderStatus.Discarded:
        return $localize`:@@statusDiscarded:Discarded`;
      case OrderStatus.Modified:
        return $localize`:@@statusModified:Modified`;
      case OrderStatus.Pending:
        return $localize`:@@statusPending:Pending`;
      case OrderStatus.Processed:
        return $localize`:@@statusProcessed:Processed`;
      case OrderStatus.Shipped:
        return $localize`:@@statusShipped:Shipped`;
      case OrderStatus.Submitted:
        return $localize`:@@statusSubmitted:Submitted`;
      case OrderStatus.Draft:
        return $localize`:@@draft:Draft`;
      case OrderStatus.PartialReceived:
        return $localize`:@@orderPartiallyReceived:Partially Received`;
      case OrderStatus.Received:
        return $localize`:@@statusReceived:Received`;
    }
  }
}
