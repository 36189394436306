<supy-dialog
  class="retailer-add-order-items-drawer"
  initialState="open"
  position="right"
  stretch="vertical"
  width="50vw"
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
>
  <supy-dialog-header>
    <header class="retailer-add-order-items-drawer__header">
      <supy-button variant="icon" title="Close" (click)="closeDialog()">
        <supy-icon name="close-circle" size="medium"></supy-icon>
      </supy-button>

      <div class="retailer-add-order-items-drawer__title">
        <supy-avatar
          [src]="supplier.logoUrl"
          [initials]="channel?.displayName?.supplier ?? supplier.name"
          size="medium"
        ></supy-avatar>
        <h6>{{ channel?.displayName?.supplier ?? supplier.name }}</h6>
      </div>
    </header>
  </supy-dialog-header>
  <supy-dialog-content>
    <supy-loading-overlay *ngIf="isLoading$ | async"></supy-loading-overlay>
    <supy-filter-group
      [filterGroup]="filtersGroup"
      (filtersChange)="searchChanged.emit($event.search)"
    ></supy-filter-group>
    <supy-grid
      class="retailer-add-order-items-drawer__grid"
      primaryKey="id"
      cellSelection="none"
      [data]="channelItems$ | async"
      [sortStrategy]="sortStrategy"
    >
      <supy-grid-column width="40%" header="Package Item Name" i18n-header="@@packageItemName" field="name">
        <ng-template supyGridCell let-value="value">
          <span>
            {{ value.en ?? value?.ar }}
          </span>
        </ng-template>
      </supy-grid-column>
      <supy-grid-column
        *ngIf="showPrice"
        width="15%"
        header="Price ({{ currency }})"
        i18n-header="@@common.priceInterpolation"
        field="price"
      >
        <ng-template supyGridCell let-value="value">
          {{ value | number: '1.2-2' }}
        </ng-template>
      </supy-grid-column>
      <supy-grid-column width="30%" header="Qty" i18n-header="@@qty">
        <ng-template supyGridCell let-rowData="rowData">
          <supy-counter-input
            [value]="getQuantity(rowData)"
            [step]="1"
            [min]="0"
            name="qty"
            (valueChange)="updateOrderedQuantity(rowData, $event)"
          ></supy-counter-input>
        </ng-template>
      </supy-grid-column>
      <supy-grid-footer>
        <supy-pagination
          *supyContext="{
            request: requestMetadata$ | async,
            response: responseMetadata$ | async
          } as metadata"
          [pageIndex]="metadata.request?.page"
          [previousDisabled]="metadata.request?.page === 0"
          [nextDisabled]="metadata.response?.count < metadata.request?.limit"
          (indexChange)="pageChanged.emit($event)"
        ></supy-pagination>
      </supy-grid-footer>
    </supy-grid>
  </supy-dialog-content>
  <supy-dialog-footer>
    <supy-button
      color="primary"
      [disabled]="validChannelOrderItems.length < 1"
      (click)="onAdd()"
      i18n="@@common.actions.addItemsToOrder"
      >Add Items to Order</supy-button
    >
  </supy-dialog-footer>
</supy-dialog>
