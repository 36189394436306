import { NonFunctionProperties } from '@supy/core';

import { SupplierReturnBaseChannelItem, SupplierReturnChannelItemRequest } from '../../entities';

export class SupplierReturnChannelItemPayload extends SupplierReturnBaseChannelItem {
  constructor(args: NonFunctionProperties<SupplierReturnChannelItemPayload>) {
    super(args);
  }

  serialize(): SupplierReturnChannelItemRequest {
    const { createdAt, createdBy, updatedAt, updatedBy, ...packagingPayload } = this.packaging;

    return {
      id: this.id,
      inventoryItem: { id: this.inventoryItem.id },
      name: this.name,
      retailerItem: { id: this.retailerItem.id },
      supplierItem: { id: this.supplierItem.id },
      itemCode: this.itemCode,
      packaging: packagingPayload,
      preferredType: this.preferredType,
      uom: this.uom,
    };
  }
}
