import { getApiDetailsDecorator, LocalFile } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';

import { SupplierReturnDocumentAttachment, SupplierReturnDocumentResponse } from './supplier-return-document.model';

const ApiProperty = getApiDetailsDecorator<SupplierReturnDocumentResponse>();

export abstract class SupplierReturnBaseDocument {
  protected constructor(args: NonFunctionProperties<SupplierReturnBaseDocument>) {
    this.number = args.number;
    this.returnDate = args.returnDate;
  }

  @ApiProperty() readonly number: string;
  @ApiProperty({ key: 'documentDate' }) readonly returnDate: Date;

  static deserialize(data: SupplierReturnDocumentResponse): NonFunctionProperties<SupplierReturnBaseDocument> {
    return {
      number: data.number,
      returnDate: new Date(data.documentDate),
    };
  }
}

export class SupplierReturnDocument extends SupplierReturnBaseDocument {
  private constructor(args: NonFunctionProperties<SupplierReturnDocument>) {
    super(args);

    this.attachments = args.attachments ?? [];
    this.localFiles = args.localFiles ?? [];
  }

  @ApiProperty() readonly attachments?: SupplierReturnDocumentAttachment[];

  readonly localFiles?: LocalFile[];

  static deserialize(data: SupplierReturnDocumentResponse): SupplierReturnDocument {
    return new SupplierReturnDocument({
      ...super.deserialize(data),
      attachments: data.attachments,
    });
  }
}
