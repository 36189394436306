import { InventoryChannelItem } from '@supy/channel-items';
import { getApiDetailsDecorator, IdType, LocalizedData, PreferredType, Uom } from '@supy/common';
import { NonFunctionProperties } from '@supy/core';
import { Packaging } from '@supy/packaging';

import { SupplierReturnChannelItemResponse } from './supplier-return-channel-item.model';

const ApiProperty = getApiDetailsDecorator<SupplierReturnChannelItemResponse>();

export abstract class SupplierReturnBaseChannelItem {
  protected constructor(args: NonFunctionProperties<SupplierReturnBaseChannelItem>) {
    this.id = args.id;
    this.inventoryItem = args.inventoryItem;
    this.itemCode = args.itemCode ?? null;
    this.name = args.name;
    this.packaging = args.packaging ?? null;
    this.preferredType = args.preferredType ?? null;
    this.retailerItem = args.retailerItem;
    this.supplierItem = args.supplierItem;
    this.uom = args.uom ?? null;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly inventoryItem: IdType;
  @ApiProperty() readonly itemCode?: string;
  @ApiProperty() readonly name: LocalizedData;
  @ApiProperty() readonly packaging?: Packaging;
  @ApiProperty() readonly preferredType?: PreferredType;
  @ApiProperty() readonly retailerItem: IdType;
  @ApiProperty() readonly supplierItem: IdType;
  @ApiProperty() readonly uom?: Uom;
}

export class SupplierReturnChannelItem extends SupplierReturnBaseChannelItem {
  private constructor(args: NonFunctionProperties<SupplierReturnChannelItem>) {
    super(args);
  }

  static deserialize(data: SupplierReturnChannelItemResponse): SupplierReturnChannelItem {
    return new SupplierReturnChannelItem({
      id: data.id,
      inventoryItem: data.inventoryItem,
      itemCode: data.itemCode,
      name: data.name,
      packaging: data.packaging,
      preferredType: data.preferredType,
      retailerItem: data.retailerItem,
      supplierItem: data.supplierItem,
      uom: data.uom,
    });
  }

  static fromInventory(data: InventoryChannelItem, { uom }: InventoryDeserializedArgs): SupplierReturnChannelItem {
    return new SupplierReturnChannelItem({
      id: data.id,
      name: data.name,
      itemCode: data.itemCode,
      uom,
      packaging: data.packaging,
      retailerItem: data.retailerItem,
      supplierItem: data.supplierItem,
      preferredType: data.preferredType,
      inventoryItem: data.inventoryItem,
    });
  }
}

interface InventoryDeserializedArgs {
  readonly uom: Uom;
}
