import {
  getApiDetailsDecorator,
  IdType,
  LocalizedSkeletonObjectType,
  SimpleUser,
  SkeletonObjectType,
  SupplierSnapshot,
} from '@supy/common';
import { BadgeStatus } from '@supy/components';
import { NonFunctionProperties } from '@supy/core';

import { SimpleChannel } from '../order';
import {
  SupplierReturnActivity,
  SupplierReturnActivityType,
  SupplierReturnMetadata,
  SupplierReturnResponse,
  SupplierReturnStatus,
  SupplierReturnTotals,
} from './supplier-return.model';
import { SupplierReturnDocument } from './supplier-return-document.entity';
import { SupplierReturnItem } from './supplier-return-item.entity';

const ApiProperty = getApiDetailsDecorator<SupplierReturnResponse>();

type SupplierReturnArgs = Omit<
  NonFunctionProperties<SupplierReturn>,
  | 'closedOn'
  | 'isPosted'
  | 'isSaved'
  | 'isDownloadable'
  | 'isLocked'
  | 'isLockable'
  | 'isUnlockable'
  | 'displayedNumber'
>;

export class SupplierReturn {
  private constructor(args: SupplierReturnArgs) {
    this.id = args.id;
    this.supplier = args.supplier;
    this.retailer = args.retailer;
    this.location = args.location;
    this.outlet = args.outlet;
    this.channel = args.channel;
    this.status = args.status;
    this.document = args.document;
    this.activities = args.activities ?? [];
    this.items = args.items ?? [];
    this.totals = args.totals;
    this.createdBy = args.createdBy;
    this.createdAt = args.createdAt;
    this.metadata = args.metadata ?? null;
    this.comment = args.comment;
    this.displayedNumber = this.document?.number?.includes('RETURN')
      ? this.document.number.split('-').at(1)
      : this.document?.number;
    this.closedOn = this.activities.find(({ type }) => type === SupplierReturnActivityType.Posted)?.createdAt;
    this.isPosted = this.status === SupplierReturnStatus.Posted;
    this.isSaved = this.status === SupplierReturnStatus.Saved;
    this.isDownloadable = Boolean(this.id);
    this.isLocked = this.metadata?.isLocked && this.isSaved;
    this.isLockable = this.isSaved && !this.isLocked;
    this.isUnlockable = this.isSaved && this.isLocked;
  }

  @ApiProperty() readonly id: string;
  @ApiProperty() readonly supplier: SupplierSnapshot;
  @ApiProperty() readonly retailer: IdType;
  @ApiProperty() readonly location: SkeletonObjectType;
  @ApiProperty() readonly outlet: LocalizedSkeletonObjectType;
  @ApiProperty() readonly channel: SimpleChannel;
  @ApiProperty() readonly status: SupplierReturnStatus;
  @ApiProperty() readonly document: SupplierReturnDocument;
  @ApiProperty() readonly activities?: SupplierReturnActivity[];
  @ApiProperty() readonly items: SupplierReturnItem[];
  @ApiProperty() readonly totals: SupplierReturnTotals;
  @ApiProperty() readonly createdBy: SimpleUser;
  @ApiProperty() readonly createdAt: Date;
  @ApiProperty() readonly metadata: SupplierReturnMetadata;
  @ApiProperty() readonly comment: string;
  readonly displayedNumber: string;
  readonly closedOn: Date;
  readonly isDownloadable: boolean;
  readonly isLocked: boolean;
  readonly isPosted: boolean;
  readonly isSaved: boolean;
  readonly isLockable: boolean;
  readonly isUnlockable: boolean;

  static deserialize(data: SupplierReturnResponse): SupplierReturn {
    return new SupplierReturn({
      id: data.id,
      supplier: data.supplier,
      retailer: data.retailer,
      location: data.location,
      outlet: data.outlet,
      channel: data.channel,
      status: data.status,
      document: data.document ? SupplierReturnDocument.deserialize(data.document) : null,
      activities: data.activities,
      items: (data.items ?? []).map(item => SupplierReturnItem.deserialize(item)),
      totals: data.totals,
      createdBy: data.createdBy,
      createdAt: data.createdAt,
      metadata: data.metadata,
      comment: data.comment,
    });
  }

  static deserializeNew({
    channel,
    location,
    outlet,
    supplier,
    number,
  }: DeserializedNewSupplierReturnArgs): SupplierReturn {
    return new SupplierReturn({
      activities: [],
      channel,
      comment: null,
      createdAt: new Date(),
      createdBy: null,
      document: {
        number,
        returnDate: null,
      },
      id: null,
      items: [],
      location,
      metadata: null,
      outlet,
      retailer: null,
      status: SupplierReturnStatus.New,
      supplier,
      totals: null,
    });
  }

  static deserializeExisting({ channel, ...props }: SupplierReturn): SupplierReturn {
    return new SupplierReturn({
      ...props,
      channel,
    });
  }
}

export const SupplierReturnStatusNameMapper: Record<SupplierReturnStatus, string> = {
  [SupplierReturnStatus.Saved]: $localize`:@@saved:Saved`,
  [SupplierReturnStatus.Posted]: $localize`:@@posted:Posted`,
  [SupplierReturnStatus.New]: $localize`:@@new:New`,
  [SupplierReturnStatus.Discarded]: $localize`:@@discarded:Discarded`,
  [SupplierReturnStatus.Pending]: $localize`:@@pending:Pending`,
};

export const SupplierReturnStatusBadgeMapper: Record<SupplierReturnStatus, BadgeStatus> = {
  [SupplierReturnStatus.Saved]: 'info',
  [SupplierReturnStatus.Posted]: 'success',
  [SupplierReturnStatus.New]: 'primary',
  [SupplierReturnStatus.Discarded]: 'light-error',
  [SupplierReturnStatus.Pending]: 'primary',
};

export type DeserializedNewSupplierReturnArgs = Pick<SupplierReturn, 'channel' | 'supplier' | 'outlet' | 'location'> &
  Pick<SupplierReturnDocument, 'number'>;
