import { User } from '@supy/common';

export enum OrderUpdateStatus {
  draft = 'draft',
  submitted = 'submitted',
  confirmed = 'confirmed',
  received = 'received',
  partialReceived = 'partial-received',
  comment = 'comment',
  shipped = 'shipped',
  discarded = 'discarded',
  rejected = 'rejected',
  modified = 'modified',
  pushed = 'pushed',
}

type OrderUpdateArgs = Pick<OrderUpdate, 'userId' | 'orderId'>;
type OrderUpdateCommentArgs = Pick<OrderUpdate, 'userId' | 'orderId' | 'comment'>;

export class OrderUpdate {
  readonly userId: string;
  readonly user?: User;
  readonly orderId: string;
  readonly status: OrderUpdateStatus;
  readonly createdAt: Date;
  readonly comment?: string;

  constructor(args: Partial<OrderUpdate>) {
    this.userId = args.userId;
    this.orderId = args.orderId;
    this.status = args.status;
    this.createdAt = args.createdAt;
    this.comment = args.comment;
  }

  static draft(args: OrderUpdateArgs): OrderUpdate {
    return new OrderUpdate({
      ...args,
      status: OrderUpdateStatus.draft,
      createdAt: new Date(),
    });
  }

  static submitted(args: OrderUpdateArgs): OrderUpdate {
    return new OrderUpdate({
      ...args,
      status: OrderUpdateStatus.submitted,
      createdAt: new Date(),
    });
  }

  static confirmed(args: OrderUpdateArgs): OrderUpdate {
    return new OrderUpdate({
      ...args,
      status: OrderUpdateStatus.confirmed,
      createdAt: new Date(),
    });
  }

  static received(args: OrderUpdateArgs): OrderUpdate {
    return new OrderUpdate({
      ...args,
      status: OrderUpdateStatus.received,
      createdAt: new Date(),
    });
  }

  static comment(args: OrderUpdateCommentArgs): OrderUpdate {
    return new OrderUpdate({
      ...args,
      status: OrderUpdateStatus.comment,
      createdAt: new Date(),
    });
  }
}
