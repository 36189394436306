<header class="retailer-order-details-header">
  <div class="retailer-order-details-header__wrapper">
    <div class="retailer-order-details-header__title">
      <div *ngIf="isInitialLoading" class="retailer-order-details-header__title--initial-loading"></div>
      <h4>{{ order ? 'Order # ' + order.number : 'New Order' }}</h4>
      <supy-retailer-order-status-badge
        [status]="order?.status"
        [isInventoryOrder]="isInventoryOrder"
        [isCkOrder]="isCkOrder"
      ></supy-retailer-order-status-badge>
    </div>
    <div class="retailer-order-details-header__icons">
      <ng-content></ng-content>
      <ng-container *ngIf="order && orders?.length > 0">
        <supy-button
          variant="icon"
          title="Previous"
          color="primary-blend"
          name="previous"
          [disabled]="previousDisabled"
          (click)="previousClicked.emit(order)"
        >
          <supy-icon name="long-arrow-left-circle"></supy-icon>
        </supy-button>
        <supy-button
          variant="icon"
          title="Next"
          color="primary-blend"
          name="next"
          [disabled]="nextDisabled"
          (click)="nextClicked.emit(order)"
        >
          <supy-icon name="long-arrow-right-circle"></supy-icon>
        </supy-button>
      </ng-container>

      <supy-dropdown-toggle *ngIf="order" [dropdownRef]="supyDropdown" position="bottom-left" action>
        <supy-button color="default" variant="icon" name="more-actions">
          <supy-icon name="dots"></supy-icon>
        </supy-button>
      </supy-dropdown-toggle>
      <supy-dropdown #supyDropdown action>
        <supy-dropdown-item (click)="downloadPdfClicked.emit()">
          <supy-icon name="document-download"></supy-icon>
          <span i18n="@@common.actions.downloadPdf">Download PDF</span>
        </supy-dropdown-item>
      </supy-dropdown>
    </div>
  </div>
</header>
