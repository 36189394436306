<supy-dialog
  class="supy-add-order-items"
  (dialogClosed)="onDialogClosed($event)"
  (dialogClosing)="onDialogClosing($event)"
  (dialogOpened)="onDialogOpened($event)"
  (dialogOpening)="onDialogOpening($event)"
  initialState="open"
  position="right"
  stretch="vertical"
  width="50vw"
>
  <supy-dialog-header>
    <supy-icon class="supy-add-order-items__close-icon" (click)="closeDialog()" name="close" size="small"></supy-icon>
    <div class="supy-add-order-items__header">
      <div class="supy-add-order-items__header-row">
        <supy-avatar
          [initials]="channel?.displayName?.supplier ?? supplier.name"
          [src]="supplier.logoUrl"
          size="medium"
        ></supy-avatar>
        <div class="supy-add-order-items__header-column">
          <h4 class="supy-add-order-items__title">{{ channel?.displayName?.supplier ?? supplier.name }}</h4>
          <p class="supy-add-order-items__subtitle">{{ orderItems?.length }} ingredients</p>
        </div>
      </div>
      <div class="supy-add-order-items__header-row--end">
        <div class="supy-add-order-items__actions">
          <supy-button
            *ngIf="!hideAddNewItemButton"
            color="secondary"
            name="create-item"
            (buttonClick)="createItem.emit()"
            i18n="@@common.actions.addNewItem"
            >Add new item</supy-button
          >
          <supy-search-input
            [latency]="700"
            (valueChange)="searchItems.emit($event)"
            name="ingredients-search"
          ></supy-search-input>
        </div>
      </div>
    </div>
  </supy-dialog-header>
  <supy-dialog-content>
    <div class="supy-add-order-items__container">
      <div
        class="supy-add-order-items__content"
        *supyContext="{
          requestMetadata: requestMetadata$ | async,
          responseMetadata: responseMetadata$ | async
        } as context"
      >
        <supy-grid
          class="supy-grid"
          cellSelection="none"
          [data]="orderItems"
          [allowFiltering]="false"
          primaryKey="productId"
          [paginationMode]="paginationMode"
          [page]="context.requestMetadata?.page"
          [perPage]="context.requestMetadata?.limit"
          [prevPageDisabled]="context.requestMetadata?.page === 0"
          [nextPageDisabled]="context.responseMetadata?.count < context.requestMetadata?.limit"
          (pageChange)="pageChange.emit($event)"
        >
          <supy-grid-column
            header="Supplier Item Code"
            i18n-header="@@supplierItemCode"
            field="itemCode"
            width="15%"
          ></supy-grid-column>
          <supy-grid-column
            width="40%"
            header="Package Item Name"
            i18n-header="@@packageItemName"
            dataType="string"
            field="name"
          >
            <ng-template supyGridCell let-value="value">
              <div class="place-order__item-color"></div>
              <div class="flex align-items-center flex-gap-sm">
                <span>{{ value | titlecase }}</span>
              </div>
              <span class="supy-add-order-items__grid-border"></span>
            </ng-template>
          </supy-grid-column>
          <supy-grid-column
            width="20%"
            header="Price ({{ currency() }})"
            i18n-header="@@common.priceInterpolation"
            dataType="number"
            field="price"
          >
            <ng-template supyGridCell let-rowData="rowData">
              <div class="flex align-items-center flex-gap-sm">
                <span>{{ rowData.price | supyPrecision: currencyPrecision() }} </span>
              </div>
              <span class="supy-add-order-items__grid-border"></span>
            </ng-template>
          </supy-grid-column>
          <supy-grid-column width="25%" header="Qty" i18n-header="@@qty" field="quantity" dataType="number">
            <ng-template supyGridCell let-value let-rowData="rowData">
              <supy-counter-input
                [value]="getQuantity(rowData)"
                [step]="1"
                [min]="0"
                name="qty"
                (valueChange)="onQuantityChanges(rowData, $event)"
              ></supy-counter-input>
              <span class="supy-add-order-items__grid-border"></span>
            </ng-template>
          </supy-grid-column>
        </supy-grid>

        <supy-loading-overlay *ngIf="isLoading$ | async"></supy-loading-overlay>
      </div>
    </div>
  </supy-dialog-content>
  <supy-dialog-footer>
    <div class="supy-add-order-items__actions">
      <supy-button
        name="receive-order"
        color="primary"
        [disabled]="selectedOrderItems.size === 0"
        (buttonClick)="onAdd()"
        >{{ submitButtonText }}</supy-button
      >
    </div>
  </supy-dialog-footer>
</supy-dialog>
